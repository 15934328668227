import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import AnimatedButton from 'components/buttons/animated-button'
import SEO from 'components/seo'
import styles from './404.module.css'

const NotFoundPage = ({ data }) => {
  const { fluid } = data.backgroundImage.childImageSharp
  const leftTriangle = data.triangleLeft.childImageSharp.fluid
  const rightTriangle = data.triangleRight.childImageSharp.fluid

  return (
    <>
      <SEO title="404: Not found" />
      
      <div className={styles.container}>
        <Img fluid={rightTriangle} className={styles.leftTriangleWhite} />
        <Img fluid={leftTriangle} className={styles.leftTriangle} />
        <Img fluid={rightTriangle} className={styles.rightTriangle} />
        <Img fluid={fluid} className={styles.image} />
        <h1 className={styles.title}>Page Not Found</h1>
        <p className={styles.content}>The page you are looking for was moved, removed, renamed or might have never existed.</p>

        <div className={styles.buttonContainer}>
          <AnimatedButton to='/' message='Return Home' className={styles.button} dark />
          <AnimatedButton to='/contact' message='Reach Out' className={styles.button} dark />
        </div>
      </div>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "not-found.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    triangleLeft: file(relativePath: { eq: "triangle.png" }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    triangleRight: file(relativePath: { eq: "right-corner-triangle.png" }) {
      childImageSharp {
        fluid(maxWidth: 850, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`